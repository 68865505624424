import React, { Component } from "react"
import { Link } from "gatsby"
import Downshift from "downshift"
import styled from "styled-components"

const SortCategory = styled.div`
  text-align: left;
  .sort-option {
    cursor: pointer;
    user-select: none;
    transition: background 120ms ease-in 0s;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 36px;
    border-radius: 3px;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 1;
    padding-left: 35px;
    padding-right: 35px;
    font-weight: 500;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px,
      rgba(137, 137, 137, 0.3) 0px 0px 0px 1px inset;
    margin-bottom: 20px;
    margin-right: 8px;
  }
  .sort-option:hover {
    color: #111;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px,
      rgba(0, 0, 0, 0.47) 0px 0px 0px 1px inset;
  }
`
const CategoryDropdown = styled.div`
  position: absolute;
  z-index: 2;
  top: 45px;
  width: 200px;
  box-shadow: 0 0 40px 0 rgba(32, 32, 32, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  border-radius: 12px;
  .dropdown-item {
    background: #f6f7f9;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    height: 42px;
    line-height: 2.5;
    color: #202020;
    font-size: 16px;
    text-align: left;
    padding: 0 36px 0 24px;
    align-items: center;
    justify-content: space-between;
    transition: background 0.1s ease-in-out;
    a:-webkit-any-link {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .dropdown-item:first-child {
    border-radius: 12px 12px 0px 0px;
  }
  .dropdown-item:last-child {
    border-radius: 0px 0px 12px 12px;
  }
`

export default class DownshiftThree extends Component {
  constructor(props) {
    super(props)
    this.books = [
      { name: "Treadmills", link: "/treadmills" },
      { name: "Exercise Bikes", link: "/exercise-bikes" },
      { name: "Rowers", link: "/rowers" },
      { name: "Ellipticals", link: "/ellipticals" },
      { name: "Home Gyms", link: "/home-gyms" },
      { name: "Gear", link: "/" },
    ]

    this.state = {
      selectedBook: "",
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(selectedBook) {
    this.setState({ selectedBook: selectedBook.name })
  }

  render() {
    return (
      <SortCategory>
        <Downshift
          onChange={this.onChange}
          selectedItem={this.state.selectedBook}
          itemToString={books => (books ? books.name : "")}
          itemToString={books => (books ? books.name : "")}
        >
          {({
            isOpen,
            getToggleButtonProps,
            getItemProps,
            highlightedIndex,
            selectedItem: dsSelectedItem,
            getLabelProps,
          }) => (
            <div>
              <label style={{ display: "block" }} {...getLabelProps()}></label>{" "}
              <div
                className="dropdown-button sort-option"
                {...getToggleButtonProps()}
              >
                {this.state.selectedBook !== "treadmills"
                  ? this.state.selectedBook
                  : "Category"}
              </div>
              <CategoryDropdown>
                {isOpen ? (
                  <div className="downshift-dropdown">
                    {this.books.map((item, index) => (
                      <div
                        className="dropdown-item"
                        {...getItemProps({ key: index, index, item })}
                        style={{
                          backgroundColor:
                            highlightedIndex === index ? "#f1f1f1" : "white",
                          fontWeight: dsSelectedItem === item ? "bold" : "500",
                          color: "inherit",
                          textDecoration: "none !important",
                        }}
                      >
                        <Link to={item.name}>{item.name}</Link>
                      </div>
                    ))}
                  </div>
                ) : null}
              </CategoryDropdown>
            </div>
          )}
        </Downshift>
      </SortCategory>
    )
  }
}
